@import "Vars.style";
@import "Fonts.style";

* {
  box-sizing: border-box;
  font-style: normal;
  font-weight: 300;
}

html,
body {
  color: $black;
  background-color: $page-background !important;
  height: 100%;
  font-family: "Segoe UI", Arial, Helvetica, sans-serif !important;
  overflow: hidden;
}

.react-uwp-theme-dark {
  background-color: $page-background !important;
}

#root {
  height: 100%;
  width: 100%;
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}

.base,
.dashboard {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
p,
label,
input,
button,
div,
a {
  font-family: "Segoe UI", Arial, Helvetica, sans-serif !important;
}

ul {
  font-family: "Segoe UI", Arial, Helvetica, sans-serif !important;
  list-style: none;
  margin: 0;
  padding: 0;
}

svg {
  vertical-align: middle;
}
