@import "../../resources/Vars.style.scss";

.map-payment {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;

  &__fake-map {
    width: 100%;
    height: 100%;
    background-image: url("./assets/map_background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__map-wrapper {
    width: 100%;
    height: 100%;
    padding-left: 50px;
  }

  &__disabled {
    width: 100%;
    height: 100%;
    z-index: 500;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
