@import "resources/Vars.style.scss";

.configs {
  .em {
    color: $primary-light !important;
    margin-left: 0;
  }

  &__plans {
    &__base {
      width: 100%;
      height: 100%;
    }
    
    &__top {
      color: $active-gray;
    }

    &__session {
      margin-bottom: 10px;
    }

    &__title {
      font-weight: bold;
      margin-bottom: 5px;
    }

    &__description {
      margin-bottom: 20px;
    }

    &__em {
      color: #FDBE32 !important;
      margin-top: -18px;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 0.9rem;
    }

    &__hr {
      border-color: #a6a6a6 !important;
      margin-top: 0 !important;
      margin-bottom: 25px !important;
    }

    &__button {
      margin-top: 10px;
      text-align: right;

      button {
        font-size: 1.1em;
        padding: 10px 20px;
        border: 2px solid $light-gray;
      }
    }

    &__radio {
      margin-bottom: 20px;
      display: block;
      width: 100%;

      span {
        font-size: 1em !important;
        color: $white;
        font-weight: 400;
        margin-left: 10px;
      }
    }

    &__mail-verify {
      &__container {
        text-align: left;
      }

      &__button {
        text-align: center;
        margin-top: 20px;

        button {
          font-size: 1.1em;
          padding: 8px 20px;
        }
      }

      &__counter {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        align-items: center;
        margin-top: 10px;

        &__number {
          font-size: 1.5em;
          font-weight: 700;
        }

        &__text {
          margin-top: 3px;
          font-size: 1em;
          width: 60%;
        }
      }
    }
  }
}
