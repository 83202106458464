.map {
  &__rule {
    &__tooltip {
      &__distance {
        font-weight: bold;
        margin-right: 8px;
      }

      &__bearing {
        font-weight: bold;
      }

      &__add {
        background-color: transparent;
        border: none;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
