@import 'resources/Vars.style.scss';

.auth {
  &__create {
    &__terms-checkbox {
      margin-top: 0 !important;
      
      & span {
        display: inline-block;
        width: 100%;
        max-width: 500px;

        @media (max-width: 600px) {
          max-width: 250px;
        }
      }
    }

    &__terms-error {
      padding: 0 !important;
      margin: 0 0 5px 0 !important;
      height: 20px;
      color: $warning-inversed;
    }
  }
}
