.auth {
  &__create {
    &__radio-session {
      margin-bottom: 30px;
    }

    &__radio-wrapper {
      margin: 15px 0;
    }

    &__radio span {
      font-size: 1rem;
    }
  }
}
