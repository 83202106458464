@import 'resources/Vars.style.scss';

.app-modal-desktop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000000;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    width: 655px;
    height: 391px;
    border-radius: 10px;
    background-color: #fcfcfc;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

    &__header {
      display: flex;
      justify-content: space-between;

      &__box {
        width: 48px;
        height: 32px;
        background-color: #00a877;
        border-radius: 10px 0px 0px 0px;
      }

      &__close {
        margin: 24px 24px 0 0;
        cursor: pointer;
      }
    }

    &__title__container {
      margin-top: 4px;
      margin-left: 56px;

      h1 {
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        line-height: 115%;
        color: #000000;
      }
    }

    &__body {
      display: flex;
      flex-direction: row;
      margin-top: 36px;
      margin-left: 56px;

      &__text {
        margin-right: 42px;
        margin-left: 47px;
        padding-top: 12px;

        p {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 130%;
          color: #1E262A;
          margin-bottom: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 840px) {
    &__content {
      width: 555px;
      height: 391px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    }
  }
}
