@import "resources/Vars.style.scss";

.promo-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: transparent;
  outline: none;
  border: none;
  width: 90%;
  max-width: 800px;

  img {
    width: 100%;
  }

  &__overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000000 !important;
  }
  
  &__close {
    position: absolute;
    right: -18px;
    top: -18px;
    width: 50px;
    height: 50px;
    background-color: $primary-color;
    font-size: 30px;
    padding: 3px 5px;
    text-align: center;
    color: $white;
    border-radius: 50%;
    cursor: pointer;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    outline: none;

    &:focus {
      outline: none;
    }
    
    &:hover {
      background-color: $primary-dark;
    }
  }
}
