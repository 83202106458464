@import "resources/Vars.style.scss";

.configs {
  &__credit-card {
    &__cards {
      width: 100%;
    }

    &__alert {
      background-color: $warning-color;
      color: $black;

      text-align: center;
      font-weight: 500;
      padding: 10px 20px;
    }

    &__helper {
      color: $warning-color;
      font-weight: 500;
      margin-top: -15px;
      margin-left: 2px;
    }

    &__separator {
      margin: 25px 0 !important;
      border-color: #a6a6a6 !important;
    }

    &__formItem {
      margin: 20px 0;

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: 400;
      }

      &__valid-to {
        display: flex;

        .fluid-dropdown__control {
          background-color: #1e262a !important;
          height: 30px;
        }

        .fluid-dropdown__indicator-separator {
          background-color: transparent;
        }
      }

      &__small {
        width: 100% !important;
        max-width: 120px !important;

        &--valid-to {
          width: 100% !important;
          max-width: 120px !important;
          margin-right: 20px;
        }
      }
    }

    &__button {
      margin-top: 10px;
      text-align: right;

      button {
        font-size: 1.1em;
        padding: 10px 20px;
        border: 2px solid $light-gray;
      }
    }
  }
}
