@import 'resources/Vars.style.scss';

.auth {
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 600px;
    height: 500px;

    background-color: #262626;
    border: 1px solid black;
    box-shadow: 0px 3px 6px #00000029;

    padding: 50px 50px;

    color: $white;

    @media (max-width: 600px) {
      border: none;
      box-shadow: none;
      height: 320px !important;
      padding: 10px 30px;
    }

    @media (max-width: 300px) {
      padding: 10px 10px;
    }

    &__logo-container {
      text-align: right;
      flex: 0;
      margin-bottom: 20px;

      @media (max-width: 600px) {
        margin-bottom: 10px;
      }

      img {
        width: 100%;
        max-width: 180px;

        @media (max-width: 600px) {
          max-width: 150px;
        }
      }
    }

    &__title {
      flex: 0;
      font-size: 2rem;

      @media (max-width: 600px) {
        font-size: 1.5rem;
      }
    }

    &__content {
      flex: 1;

      padding: 15px 0;
      font-size: 1.1rem;
      color: $white;

      @media (max-width: 600px) {
        font-size: 0.9rem;
        padding: 5px 0;
      }

      p {
        margin: 20px 0;
      }

      &__link {
        color: $primary-light;
        cursor: pointer;
        outline: none;

        &:hover,
        &:focus {
          color: $primary-bright;
        }
      }
    }

    &__actions {
      flex: 0;
      display: flex;
      justify-content: flex-end;

      &__previous {
        margin-right: 10px;
        width: 120px;
        height: 40px;
        font-size: 1.1rem;

        @media (max-width: 600px) {
          width: 100px;
          height: 35px;
          font-size: 0.9rem;
        }

        &--only {
          margin-right: 0;
        }
      }

      &__next {
        width: 120px;
        height: 40px;
        font-size: 1.1rem;

        @media (max-width: 600px) {
          width: 100px;
          height: 35px;
          font-size: 0.9rem;
        }
      }
    }

    &__loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10000;

      background-color: rgba(0, 0, 0, 0.6);

      &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}
