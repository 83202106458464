.app-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  background-color: #fff;
  padding: 0 16px;
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  position: absolute;
  bottom: 0;
  left: 50px;
  z-index: 1000;

  &__content {
    &__left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__left__close {
      padding-top: 5px;
      cursor: pointer;
    }

    &__left__close:hover {
      opacity: 0.8;
    }

    &__left__nexatlas {
      display: flex;
      margin-left: 22px;
      align-items: center;

      &-info {
        margin-left: 8px;
        display: flex;
        flex-direction: column;

        &__title {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;
          color: #1e262a;
          margin-right: 6px;
        }

        &__subtitle {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 100%;
          color: #667c88;
        }
      }
    }

    &__right {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin-right: 130px;

      &__button-modal {
        align-items: center;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        margin-right: 8px;
        background: #f2f2f2;
        border-radius: 10px;
        height: 32px;
        width: 290px;
        
        img {
          padding-top: 6px;
          margin-left: 11px;
        }

        span {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;
          color: #1975ff;
          padding-left: 11px;
        }
      }

      &__button-modal:hover {
        opacity: 0.8;
      }

      &__container-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;

        a:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
