@import "resources/Vars.style.scss";

.map {
  &__search {
    position: fixed;
    top: 0;
    left: 0;

    background-color: $white;
    color: $black;
    width: 300px;
    height: auto;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 0.9rem;
    z-index: 10000;

    @media screen and (max-width: 600px) {
      top: 50% !important;
      left: 50% !important;
      height: 100% !important;
      transform: translateX(-40%) translateY(-50%) !important;
    }

    @media screen and (max-width: 360px) {
      width: 250px !important;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      overflow-x: hidden;
      border-bottom: 1px solid #fcfcfc;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }

      &__text {
        flex: 1;
        max-width: 80%;
        padding-right: 10px;
      }

      &__title {
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 0;
        color: $black;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      &__subtitle {
        color: $black;
        font-weight: 300;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      &__actions {
        flex: 0;
        display: flex;
        align-items: center;
      }

      &__info {
        margin-right: 15px;
        color: $black !important;
        cursor: pointer;
        font-size: 20px !important;
        display: inline-block;

        &.disabled {
          visibility: hidden;
        }
      }

      &__add {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-top: -1px;
        display: inline-block;

        &.disabled {
          visibility: hidden;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 10px !important;
    }

    &::-webkit-scrollbar-track {
      border: solid 3px transparent !important;
      background: #fcfcfc !important;
    }

    &::-webkit-scrollbar-thumb {
      // rbg(115,115,115) is equal to #bfbfbf
      box-shadow: inset 0 0 6px 6px rgb(115, 115, 115) !important;
      border: solid 3px #fcfcfc !important;
      border-radius: 14px !important;
    }
  }
}
