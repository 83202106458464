@import "resources/Vars.style.scss";

.nex-market {
  position: fixed;
  top: 0;
  left: 48px;
  width: 380px;
  height: 100%;
  border-left: 3px solid #050505;
  background-color: $panel-background;
  z-index: 9999;

  @media screen and (max-width: 430px) {
    width: calc(100% - 48px);
  }

  overflow-y: hidden;

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
