@import "resources/Vars.style.scss";
@import "resources/Mixins.style.scss";

///////************* CHANGING REACT UWP RADIO THEME ***************/////////////
.radio-button-content-1119942997,
.radio-button-content-2662250970 {
  background: #fdbe32 !important;
}
.radio-button-wrapper-2870220616 {
  border: 2px solid #fdbe32 !important;
}

///////*************  MEDIAS BIGGER THAN 920 PX OR SMALLER THAN 320 PX ***************/////////////
.map__options {
  background-color: $panel-background !important;
  box-sizing: border-box;
  position: absolute;
  right: 100px;
  top: 50%;
  color: white;
  min-width: 250px;
  opacity: 0.9;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  transform: translateY(-50%);
  justify-content: space-between;
  z-index: 20;
  font-size: 14px;

  //TURNING ALL MAP-OPTIONS UNSELECTABLE ON ALL BROWSERS
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__title {
    padding: 10px 0 15px 10px;
    text-transform: uppercase;
  }

  &__subtitle {
    padding: 40px 10px 10px 10px;
    text-transform: uppercase;
  }

  &__item {
    height: 30px;
    display: flex;
    align-items: center;
    margin-top: 0.9em;
    margin-left: 10px;
  }

  &__chartTitle {
    margin-left: 10px;
    font-weight: 400 !important;
  }

  &__checkbox {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-family: Segoe MDL2 Assets;
    transition: all 0.25s;
    border: none;
    outline: none;
    user-select: none;
    width: 20px;
    height: 20px;
    line-height: inherit;
    font-size: 18px;
    color: #fff;
    flex: 0 0 auto;
    padding: 0px;
    margin: 0px;
    transform: scale(1);
    background: #00b96d !important;
  }

  #breaker {
    height: 10px;
  }

  &__separator {
    border-top: 1px solid #2e2e2e !important;
    margin: 20px 0 5px 0;
  }
}

///////*************  MEDIAS 320-520 PX ***************/////////////
@media (min-width: 320px) and (max-width: 520px) {
  .map__options {
    background-color: $panel-background !important;
    box-sizing: border-box;
    position: absolute;
    right: 70px;
    top: 50%;
    color: white;
    min-width: 75px;
    max-height: 90%;
    opacity: 0.9;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 12px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    transform: translateY(-50%);
    justify-content: space-between;
    z-index: 20;
    font-size: 10px !important;

    //TURNING ALL MAP-OPTIONS UNSELECTABLE ON ALL BROWSERS
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__title {
      padding: 0 0 10px 10px;
      text-transform: uppercase;
      font-size: 12px;
    }

    &__subtitle {
      padding: 30px 10px 10px 10px;
      text-transform: uppercase;
      font-size: 12px;
    }

    &__item {
      height: 30px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-left: 10px;
      font-size: 12px;
    }

    &__chartTitle {
      margin-left: 10px;
      font-weight: 400 !important;
      font-size: 12px;
    }

    &__checkbox {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      font-family: Segoe MDL2 Assets;
      transition: all 0.25s;
      border: none;
      outline: none;
      user-select: none;
      width: 15px !important;
      height: 15px !important;
      line-height: inherit;
      font-size: 18px;
      color: #fff;
      flex: 0 0 auto;
      padding: 0px;
      margin: 0px;
      transform: scale(1);
      background: #00b96d !important;
    }

    #breaker {
      height: 10px;
    }

    &__separator {
      border-top: 1px solid #2e2e2e !important;
      margin: 20px 0 5px 0;
    }
  }
}

///////*************  MEDIAS 521-919 PX ***************/////////////
@media (min-width: 521px) and (max-width: 919px) {
  .map__options {
    background-color: $panel-background !important;
    box-sizing: border-box;
    position: absolute;
    right: 75px;
    top: 50%;
    color: white;
    min-width: 75px;
    max-height: 90%;
    opacity: 0.9;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 20px;
    padding-right: 25px;
    padding-top: 30px;
    padding-bottom: 25px;
    transform: translateY(-50%);
    justify-content: space-between;
    z-index: 20;
    font-size: 14px;

    //TURNING ALL MAP-OPTIONS UNSELECTABLE ON ALL BROWSERS
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__title {
      padding: 0 0 10px 10px;
      text-transform: uppercase;
      font-size: 14px;
    }

    &__subtitle {
      padding: 30px 10px 10px 10px;
      text-transform: uppercase;
      font-size: 14px;
    }

    &__item {
      height: 30px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-left: 10px;
      font-size: 14px;
    }

    &__chartTitle {
      margin-left: 10px;
      font-weight: 400 !important;
      font-size: 14px;
    }

    &__checkbox {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      font-family: Segoe MDL2 Assets;
      transition: all 0.25s;
      border: none;
      outline: none;
      user-select: none;
      width: 20px;
      height: 20px;
      line-height: inherit;
      font-size: 18px;
      color: #fff;
      flex: 0 0 auto;
      padding: 0px;
      margin: 0px;
      transform: scale(1);
      background: #00b96d !important;
    }

    #breaker {
      height: 5spx;
    }

    &__separator {
      border-top: 1px solid #2e2e2e !important;
      margin: 20px 0 5px 0;
    }
  }
}
