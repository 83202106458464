@import "resources/Vars.style.scss";

.route-planning-item {
  &__distance-field {
    background-color: #0d1012;
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    opacity: 1;

    &__bearing {
      width: 20%;
      text-align: left;
      color: #00ffb4;
    }

    &__distance {
      width: 20%;
      text-align: center;
      color: #00ffb4;
    }

    &__time {
      width: 20%;
      text-align: center;
      color: #00ffb4;
    }

    &__distance-accumulated {
      width: 20%;
      text-align: right;
      border-left: 1px solid $active-gray;
      color: #ffedc6;
    }

    &__time-accumulated {
      width: 20%;
      text-align: right;
      color: #ffedc6;
    }

    span {
      font-size: 0.9em;
      font-weight: 400;
    }

    &--dragging {
      visibility: hidden;
    }
  }
}
