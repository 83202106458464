@import "resources/Vars.style.scss";

.route-planning {
  flex-grow: 1;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $active-gray;
  }

  &--items {
    border-top: 2px solid $black;
  }

  &__list {
    flex-grow: 1;
    background-color: $panel-background;
  }

  &__no-items {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    width: 60%;
    text-align: center;
    line-height: 1.5em;
    color: $active-gray;

    &__icon {
      margin-bottom: 5px;

      img {
        width: 30px;
      }
    }
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
