.app-footer-mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__content {
    width: 100%;
    height: 210px;
    background: #fcfcfc;
    border-radius: 5px 5px 0px 0px;
    padding: 20px 22px 0px 22px;

    &__header {
      display: flex;
      align-items: center;
      margin-left: 18px;
      margin-right: 18px;

      &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 110%;
        color: #1e262a;
        margin-left: 16px;
      }
    }

    &__description {
      margin-top: 14px;
      margin-left: 18px;
      margin-right: 18px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 110%;
      color: #4a5b64;
    }

    &__buttons {
      margin-top: 16px;

      &__open {
        width: 100%;
        height: 44px;
        background: #00a877;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        span {
          font-style: normal;
          font-weight: 800;
          font-size: 14px;
          line-height: 105%;
          color: #ffffff;
        }
      }
      &__close {
        margin-top: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 105%;
          color: #667c88;
        }
      }
    }
  }

  @media screen and (max-width: 330px) {
    &__content {
      height: 250px;
    }
  }
}
