@import "resources/Vars.style.scss";

.map {
  &__meteorology-slider {
    z-index: 99;
    position: absolute;
    bottom: 75px;
    left: 60px;
    width: 40px;
    height: 150px;
    padding: 15px 15px;
  }
}

.rangeslider {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  touch-action: none;
  cursor: grab;
}

.rangeslider-vertical {
  height: 100%;
  border-radius: 10px;
}

.rangeslider-vertical .rangeslider__handle {
  display: inline-block;
  position: absolute;
  background-color: $primary-color;
  border-radius: 10px;

  transform: translateX(20%);

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.rangeslider-vertical .rangeslider__handle:active,
.rangeslider-vertical .rangeslider__handle:focus,
.rangeslider-vertical .rangeslider__handle:active,
.rangeslider-vertical .rangeslider__handle:focus {
  outline: none;
}

.rangeslider__handle-label {
  color: $white;
  font-weight: 500;
  font-size: 12px;
  background: transparent;
  padding: 4px 10px;
  text-align: right;

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  box-shadow: none;
  bottom: 0;
}
