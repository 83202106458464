@import "resources/Vars.style.scss";

.feedback {
  position: fixed;
  top: 0;
  left: 48px;
  width: 350px;
  height: 100%;
  border-left: 3px solid #050505;

  display: none;
  &--open {
    display: flex;
  }

  @media screen and (max-width: 400px) {
    width: calc(100% - 45px);
  }

  flex-direction: column;

  background-color: $panel-background;
  z-index: 9999;

  .fluid-dropdown__indicator-separator {
    display: none !important;
  }

  .fluid-dropdown__control {
    max-height: 38px !important;
    min-width: 175px !important;
    border: 2px solid #707070 !important;
  }

  .fluid-dropdown__control:active {
    border: 2px solid #707070 !important;
  }

  .fluid-dropdown__placeholder {
    color: white !important;
  }

  &__title {
    font-weight: bold;
    padding-top: 24px;
    padding-bottom: 24px;
    flex: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__scrollbar {
    overflow-x: hidden !important;
  }

  &__container {
    padding: 0 25px;

    &__dropdown {
      width: 60% !important;

      background-color: $panel-background !important;
      color: white !important;
    }

    &__session {
      margin-bottom: 30px;
      line-height: 20px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &__answerPartOne {
        margin-bottom: 1.2em;
        font-weight: 300;
      }

      &__answerPartTwo,
      &__answerPartThree {
        margin-top: 1.2em;
        font-weight: 300;
        width: 100% !important;
      }
    }

    em {
      color: $primary-color;
    }

    h2 {
      margin-bottom: 15px;
      font-size: 1.6rem;
    }

    &__textarea {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }

    &__textarea.custom-textarea,
    &__textarea.custom-textarea:focus {
      min-width: 100% !important;
      max-width: 100% !important;
      resize: none;
      background-color: $panel-background;
    }

    &__label {
      font-size: 0.8rem;
      margin: 20px 0;
    }

    &__value {
      margin: 5px 0 5px 20px;
    }

    &__button-container {
      text-align: right;
    }

    &__button {
      padding: 11px 34px 9px 34px !important;
      background-color: #1a1a1a !important;
      border: 1px solid #fcfcfc !important;

      &:disabled {
        border: 1px solid #707070 !important;
        background-color: $panel-background !important;

        &__text {
          color: #707070 !important;
          cursor: not-allowed;
        }
      }

      &__text {
        color: white;
        font-size: 0.95rem;
      }
    }
  }

  &__return {
    cursor: pointer;
    margin-top: 0.75em;
    margin-bottom: 1.4em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  textarea {
    height: 260px !important;
  }
  .custom-textarea:focus {
    background-color: #1a1a1a !important;
    color: white;
    border: 2px solid #707070 !important;
    transition: none;
  }
}

@media (max-width: 520px) {
  .feedback {
    font-size: 12px;
    width: calc(100% - 45px);
    border-left: 3px solid #050505;
  }
}
