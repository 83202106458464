@import "resources/Vars.style.scss";

.tour {
  &__logo {
    display: block;
    margin: 0 auto 20px auto;
  }

  &__text {
    margin-bottom: 10px;

    color: $white;
    font-size: 14px;
    &--cta {
      color: $warning-color;
      font-weight: 500;
      text-align: center;
    }
  }

  &__list {
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: stretch;
    align-content: stretch;

    &__row {
      margin-top: 10px;

      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      &__icon {
        color: $white;
        font-size: 25px;
        width: 25px;
        height: 25px;
        margin-right: 15px;
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  &__gif {
    margin-bottom: 15px;
    width: 267px;
    height: 150px;
    align-self: center;
    justify-self: center;

    &__drag {
      width: 267px;
      height: 210px;
    }

    &__finish {
      width: 267px;
      height: 150px;
    }
  }
}