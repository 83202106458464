@font-face {
  font-family: "Segoe UI";
  font-weight: 100;
  font-style: normal;
  src: url("./fonts/segoeuil.ttf") format("truetype");
}

@font-face {
  font-family: "Segoe UI";
  font-weight: 200;
  font-style: normal;
  src: url("./fonts/segoeuisl.ttf") format("truetype");
}

@font-face {
  font-family: "Segoe UI";
  font-weight: 300;
  font-style: normal;
  src: url("./fonts/segoeui.ttf") format("truetype");
}

@font-face {
  font-family: "Segoe UI";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/segoeuisb.ttf") format("truetype");
}

@font-face {
  font-family: "Segoe UI";
  font-weight: 500;
  font-style: italic;
  src: url("./fonts/segoeuib.ttf") format("truetype");
}