@import 'resources/Vars.style.scss';

.auth {
  &__input {
    &__wrapper {
      position: relative;
      margin-top: 12px;
      width: 100%;

      &--info-margin {
        margin-top: 45px;
      }
    }

    &__label {
      color: $label-inversed;
      font-size: 1rem;

      display: inline;
      position: absolute;
      top: 0;
      left: 0;

      pointer-events: none;

      transform: translate(4px, -1px) scale(1);
      transform-origin: 0 0;

      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

      &--active {
        transform: translate(2px, -15px) scale(0.7);
      }

      &--focused {
        color: $primary-light;
      }

      @media (max-width: 600px) {
        font-size: 0.9rem;
      }
    }

    &__base-input {
      width: 100%;
      box-sizing: border-box;
      padding: 5px 3px;

      background-color: transparent;
      color: $white;

      border: none;
      border-bottom: 1px solid $white;
      outline: none;

      transition: border-bottom 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

      &:hover {
        border-bottom: 1px solid $dark-gray-inversed;
      }

      &--warning {
        border-bottom: 1px solid $warning-inversed;
      }

      &--error {
        border-bottom: 1px solid $error-inversed;
      }

      &:focus {
        border-bottom: 1px solid $primary-light;
      }

      @media (max-width: 600px) {
        font-size: 0.9rem;
      }
    }

    &__base-input::placeholder {
      color: $label-inversed;

      @media (max-width: 600px) {
        font-size: 0.9rem;
      }
    }

    &__info {
      position: absolute;
      transform: translate(3px, -42px);
      color: $gray-inversed;

      &--warning {
        color: $warning-inversed;
      }

      &--error {
        color: $error-inversed;
      }
    }
  }
}
