@import "resources/Vars.style.scss";

.fluid-dropdown {
  &__control {
    border-radius: 0 !important;
    background-color: #222222 !important;
    height: 45px;
    border: 2px solid $light-gray !important;

    &:active, &--menu-is-open,  &--is-focused {
      border-color: $primary-color !important;
      box-shadow: none !important;
    }

    &:hover {
      border-color: $active-gray !important;
      box-shadow: none !important;
    }
  }

  &__indicator {
      cursor: pointer !important;
  }

  &__single-value {
    color: #EEEEEE !important;
  }

  &__menu {
    border-radius: 0 !important;
    background-color: #222222 !important;
    margin: 0 1px !important;
  }

  &__option {
    cursor: pointer !important;

    &--is-focused {
        background-color: #555555 !important;
    }

    &--is-selected {
        background-color: #333333 !important;
    }
  }
}
