@import "resources/Vars.style.scss";

.configs {
  &__user-waypoints {
    &__title {
      margin-bottom: 10px;
      height: 40px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      cursor: pointer;
    }
    &__base {
      width: 100%;
      height: 100%;

      &__content {
        height: calc(100% - 76px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 25px;

        &__scroll {
          flex-grow: 0;
          overflow-y: auto;
          overflow-x: hidden;
        }
        
        &__search {
          height: 40px;
          display: flex;
          gap: 10px;
          align-items: center;
          margin-bottom: 16px;

          &__button-add {
            width: 38px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 1px;
            background-color: #00a877;
            color: #fff;
            font-size: large;
          }
        }
        &__empty {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #e0e0e0;
        }

        &__list {        
          &__menu-group {
            height: fit-content;
            &__menu-item:first-child {
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
            }
            &__menu-item:last-child {
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
            }

            &__menu-item {
              border-bottom: 1px solid  $light-gray;
              height: 48px;
              background-color: $item-gray;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              padding: 0 16px;
              width: 100%;

              &__text {
                flex: 1;
                margin-left: 18px;
              }

              &:last-child {
                border-bottom: none;
              }

              &:hover {
                background-color: $light-gray;
              }

              &:disabled {
                cursor: not-allowed;
                outline: none;
              }
            }
          }
        }

        &__footer {
          margin-top: 16px;
          margin-bottom: 16px;

          &__selected {
            display: flex;
            margin-bottom: 8px;
            justify-content: space-between;

            &__warning {
            }
          }
        }
      }
    }
  }
}
