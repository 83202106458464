@import "resources/Vars.style.scss";

.methereology__options {
  background-color: $panel-background !important;
  box-sizing: border-box;
  position: absolute;
  right: 100px;
  top: 50%;
  color: white;
  min-width: 75px;
  opacity: 0.9;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 29px;
  padding-bottom: 20px;
  padding-left: 35px;
  padding-right: 50px;
  transform: translateY(-50%);
  z-index: 20;

  //TURNING ALL METHEREOLOGY-OPTIONS UNSELECTABLE ON ALL BROWSERS
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__title {
    text-transform: uppercase;
    padding-bottom: 15px !important;
  }

  &__item {
    height: 30px;
    display: flex;
    align-items: left;
    margin-top: 10px;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    font-family: Segoe MDL2 Assets;
    transition: all 0.25s;
    border: 2px !important;
    outline: none;
    user-select: none;
    width: 20px;
    height: 20px;
    line-height: inherit;
    font-size: 18px;
    color: #bfbfbf !important;
    flex: 0 0 auto;
    padding: 0px;
    margin: 0px;
    transform: scale(1);
    background: #00b96d !important;
  }

  &__chartTitle {
    display: flex;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    margin-left: 13px !important;
    font-weight: 400 !important;
  }
}

//Subscribed CSS of React UWD ---> Size > 920px OR < 320px
.checkbox-iconParent-1001638072 {
  transition: all 0.25s;
  user-select: none;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  color: #000;
  border: 2px solid #bfbfbf !important;
  width: 20px !important;
  height: 20px !important;
  background: none;
  cursor: default;
  overflow: hidden;
}

.checkbox-wrapper-484794451 {
  display: flex !important;
  align-items: center;
  vertical-align: middle;
}

///////////////////******** MEDIA QUERY 320x520px ***************/////////////////////////
@media (min-width: 320px) and (max-width: 520px) {
  .methereology__options {
    background-color: $panel-background !important;
    box-sizing: border-box;
    position: absolute;
    right: 70px;
    top: 50%;
    color: white;
    min-width: 75px;
    opacity: 0.9;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 18px;
    transform: translateY(-50%);
    z-index: 20;

    //TURNING ALL METHEREOLOGY-OPTIONS UNSELECTABLE ON ALL BROWSERS
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__title {
      margin-top: 5px;
      margin-bottom: 5px;
      text-transform: uppercase;
      font-size: 12px;
    }

    &__item {
      height: 30px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      font-size: 12px;
    }

    &__checkbox {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      font-family: Segoe MDL2 Assets;
      transition: all 0.25s;
      border: none;
      outline: none;
      user-select: none;
      width: 20px;
      height: 20px;
      line-height: inherit;
      font-size: 18px;
      color: #fff;
      flex: 0 0 auto;
      padding: 0px;
      margin: 0px;
      transform: scale(1);
      background: #00b96d !important;
    }

    &__chartTitle {
      margin-left: 10px;
      font-weight: 400 !important;
    }
  }
}

///////*************  MEDIAS 521-720 PX ***************/////////////
@media (min-width: 521px) and (max-width: 720px) {
  .methereology__options {
    background-color: $panel-background !important;
    box-sizing: border-box;
    position: absolute;
    right: 75px;
    top: 50%;
    color: white;
    min-width: 75px;
    opacity: 0.9;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 35px;
    padding-right: 50px;
    transform: translateY(-50%);
    z-index: 20;

    //TURNING ALL METHEREOLOGY-OPTIONS UNSELECTABLE ON ALL BROWSERS
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__title {
      margin-top: 10px;
      text-transform: uppercase;
      font-size: 14px;
    }

    &__item {
      height: 30px;
      display: flex;
      align-items: center;
      margin-top: 25px;
      font-size: 14px;
    }

    &__checkbox {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      font-family: Segoe MDL2 Assets;
      transition: all 0.25s;
      border: none;
      outline: none;
      user-select: none;
      width: 20px;
      height: 20px;
      line-height: inherit;
      font-size: 18px;
      color: #fff;
      flex: 0 0 auto;
      padding: 0px;
      margin: 0px;
      transform: scale(1);
      background: #00b96d !important;
    }

    &__chartTitle {
      margin-left: 10px;
      font-weight: 400 !important;
    }
  }
}

///////*************  MEDIAS 721-920 PX ***************/////////////
@media (min-width: 721px) and (max-width: 920px) {
  .methereology__options {
    background-color: $panel-background !important;
    box-sizing: border-box;
    position: absolute;
    right: 10%;
    top: 50%;
    color: white;
    min-width: 75px;
    opacity: 0.9;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 29px;
    padding-bottom: 20px;
    padding-left: 35px;
    padding-right: 50px;
    transform: translateY(-50%);
    z-index: 20;
    font-size: 14px;
    font-weight: 400 !important;

    //TURNING ALL METHEREOLOGY-OPTIONS UNSELECTABLE ON ALL BROWSERS
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__title {
      padding: 0 0 10px 10px;
      text-transform: uppercase;
    }

    &__item {
      height: 30px;
      display: flex;
      align-items: center;
      margin-top: 25px !important;
      margin-left: 10px;
    }

    &__checkbox {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      font-family: Segoe MDL2 Assets;
      transition: all 0.25s;
      border: none;
      outline: none;
      user-select: none;
      width: 20px;
      height: 20px;
      line-height: inherit;
      font-size: 18px;
      color: #fff;
      flex: 0 0 auto;
      padding: 0px;
      margin: 0px;
      transform: scale(1);
      background: #00b96d !important;
    }

    &__chartTitle {
      margin-left: 10px;
      font-weight: 400 !important;
    }
  }
}
