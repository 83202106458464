@import 'resources/Vars.style.scss';

.configs {
  &__payment-data {
    &__cards {
      width: 100%;
    }

    &__alert {
      background-color: $warning-color;
      color: $black;

      text-align: center;
      font-weight: 500;
      padding: 10px 20px;
    }

    &__separator {
      margin: 25px 0 !important;
      border-color: #a6a6a6 !important;
    }

    &__tipo {
      .fluid-dropdown__control {
        background-color: #1e262a !important;
        height: 30px;
      }

      .fluid-dropdown__indicator-separator {
        background-color: transparent;
      }
    }

    &__cpf-error {
      margin-top: 6px;
      color: #ff6565;
      font-weight: 500;
    }

    &__formItem {
      margin: 20px 0;

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: 400;
      }

      &--phone-line {
        display: flex;
        width: 100%;
      }

      &--phone-ddd {
        width: calc(20% - 5px);

        &__field {
          width: 100% !important;
        }
      }

      &--phone-number {
        width: 80%;
        margin-left: 5px;

        &__field {
          width: 100% !important;
        }
      }

      &--address-line {
        display: flex;
        width: 100%;
      }

      &--address {
        width: 70%;

        &__field {
          width: 100% !important;
        }
      }

      &--street-number {
        width: calc(30% - 5px);
        margin-left: 5px;

        &__field {
          width: 100% !important;
        }
      }

      &--born-at-line {
        display: flex;
        width: 100%;

        label {
          display: block;
        }

        .fluid-dropdown__control {
          background-color: #1e262a !important;
          height: 30px;
        }

        .fluid-dropdown__indicator-separator {
          background-color: transparent;
        }
      }

      &--born-at-day {
        width: 70%;

        &__field {
          width: 100% !important;
        }
      }

      &--born-at-month {
        width: 70%;

        &__field {
          width: 100% !important;
        }
      }

      &--born-at-year {
        width: 70%;

        &__field {
          width: 100% !important;
        }
      }
    }

    &__button {
      margin-top: 10px;
      text-align: right;

      button {
        font-size: 1.1em;
        padding: 10px 20px;
        border: 2px solid $light-gray;
      }
    }
  }
}
