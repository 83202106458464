@import "resources/Vars.style.scss";

.favorites {
  position: fixed;
  top: 0;
  left: 48px;
  width: 350px;
  height: 100%;
  border-left: 3px solid #050505;

  @media screen and (max-width: 400px) {
    width: calc(100% - 48px);
  }

  display: flex;
  flex-direction: column;

  background-color: $panel-background;
  z-index: 9999;

  &__header {
    display: flex;
    flex-direction: row;
    align-content: space-between;

    align-items: center;
  }

  &__title {
    font-weight: bold;
    flex: 1;
    padding: 0 25px;
  }

  &__options {
    display: flex;
    flex-direction: row;

    &__item {
      padding: 15px 18px 13px 18px;
      cursor: pointer;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &--active {
        border-bottom: 2px solid $white;
      }

      &__icon {
        width: 20px;
      }
    }
  }

  &__scroll {
    height: 100%;
  }

  &__no-items {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    width: 75%;
    text-align: center;
    line-height: 1.5em;
    color: $active-gray;

    &__icon {
      margin-bottom: 5px;
    }
  }
}

///////////////////******** MEDIA QUERY 320x520px ***************/////////////////////////
@media (max-width: 520px) {
  .favorites {
    font-size: 12px;

    @media screen and (max-width: 520px) {
      width: calc(100% - 48px);
    }
  }
}

///////*************  MEDIAS 521-720 PX ***************/////////////
@media (min-width: 521px) and (max-width: 720px) {
  .favorites {
    font-size: 14px;
  }
}

///////*************  MEDIAS 721-920 PX ***************/////////////
@media (min-width: 721px) and (max-width: 920px) {
  .favorites {
    font-size: 14px;
  }

  .routes-list-item {
    padding: 20px;
  }
}
