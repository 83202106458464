@import "../../../resources/Vars.style.scss";

.map,
.windy {
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.leaflet-container {
    background: rgb(232, 239, 243);
    outline: 0;
}

.leaflet-control-layers {
  display: none !important;
}

#windy #map-container {
  background-color: #f6f6f6 !important;
}

#windy #mobile-ovr-select,
#windy #embed-zoom {
  display: none !important;
}

#windy #bottom #progress-bar #playpause {
  background-color: $primary-dark;
  width: 35px;
  height: 35px;
  box-shadow: none;
  left: 2px !important;
}

#windy .play-pause::before {
  font-family: "Segoe MDL2 Assets";
  content: "\E769" !important;
  font-size: 18px;
  color: $white;
  left: 9px !important;
  top: 5px !important;
}

#windy .play-pause.off::before {
  content: "\E768" !important;
}

#windy #bottom {
  z-index: 20000 !important;
}

#windy #bottom #progress-bar #calendar {
  display: inline-block !important;
  //background-color: rgba(0, 0, 0, 0.3);
}

#windy #bottom #progress-bar #calendar div {
  padding: 3px 0 0 8px !important;
  font-weight: 400;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}

#windy .uiyellow {
  color: $gray !important;
  text-shadow: none !important;
}

#windy .timecode .box {
  background-color: $primary-color !important;
  box-shadow: none !important;
  font-weight: bold;

  &::before {
    border-top-color: $primary-color !important;
  }
}
