@import 'resources/Vars.style.scss';

.route-panel {
  position: fixed;
  top: 0;
  left: 48px;
  width: 350px;
  height: 100%;
  max-height: 100%;
  border-left: 3px solid #050505;

  display: flex;
  flex-direction: column;

  background-color: $panel-background;
  z-index: 8000;

  @media screen and (max-width: 400px) {
    width: calc(100% - 48px);
  }

  &__header {
    width: 100%;
    padding: 6em 1.8em 1.2em 1.8em;
    flex-grow: 0;

    &__head {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      display: flex;
      flex-direction: row;

      color: white;
      font-size: 1.1em;
      font-weight: bold;
      text-overflow: ellipsis;
      width: 100%;
      height: 25px;
      overflow: hidden;
      margin-bottom: 5px;

      &__route-name {
        font-weight: bold;
        line-height: 1.8em;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.disabled {
        color: $disabled-color;
      }
    }

    &__textbox {
      width: 90% !important;
      height: 25px !important;
      font-weight: bold;
    }

    &__confirm {
      cursor: pointer;
      display: inline-block;
      font-size: 20px;
      padding: 5px;

      &:hover {
        color: $primary-color;
      }
    }

    &__subline {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__actions {
      flex: 1;
      display: flex;
      justify-content: space-between;

      margin-left: -10px;
      margin-right: 10px;

      &__group {
        position: relative;

        width: 42px;
        height: 46px;

        &:first-child {
          padding-left: 0;
        }
      }

      &__item {
        width: 100%;
        height: 100%;
        padding: 5px;

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 20px;
        cursor: pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:hover {
          color: $primary-color;
        }

        &.active {
          background-color: $dropdown-gray;
        }
      }

      &__icon {
        height: 24px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &--loader {
          height: 20px;
          margin-bottom: 4px;
        }
      }
    }

    &__vs {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    &__delete {
      position: absolute;
      top: 55px;
      right: 25px;
      background-color: rgba(0, 0, 0, 0.6);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      border: 1px solid black;

      &__button {
        display: inline-block;
        padding: 5px 10px;
        margin: 10px;
        cursor: pointer;

        &:last-child {
          margin-left: 0;
        }

        &.yes {
          background-color: red;

          &:hover {
            background-color: rgba(255, 0, 0, 0.8);
          }
        }

        &.no {
          background-color: $light-gray;

          &:hover {
            background-color: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 400px) {
  .route-panel {
    &__header {
      width: 100%;
      padding: 4.5em 1.2em 1.2em 1.2em;

      &__title {
        font-size: 1em;
      }

      &__actions {
        &__icon {
          height: 20px;
        }
      }
    }
  }
}
