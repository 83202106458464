.configs {
  &__trial-check {
    &__button {
      margin-top: 10px;
      text-align: right;

      button {
        font-size: 1.1em;
      }
    }
  }
}
