@import "resources/Vars.style.scss";

.custom-textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    padding: 5px;

    background-color: $gray;
    color: $white;
    border: 2px solid $light-gray;

    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
        border: 2px solid $active-gray;
    }

    &:focus {
        outline: none !important;
        background-color: $black;
        border: 2px solid $primary-color;
    }
}