@import "resources/Vars.style.scss";

.config {
  &__dependant {
    &__item {
      &__wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;

          height: 60px;
          border-top: 1px solid $black;
      }

      &__left {
          flex: 1;
      }

      &__title {
          font-weight: 400;
      }

      &__subtitle {
      }

      &__icon {
        cursor: pointer;
        outline: none;

        IMG {
          height: 20px;
          outline: none;
        }
      }
    }
  }
}
