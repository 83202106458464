@import "resources/Vars.style.scss";

.snackbar {
  margin-left: 40px;
  margin-bottom: 25px;
  width: 350px;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__box {
    max-width: 280px;
    background-color: $white;
    color: $black;

    padding: 10px 20px;
    margin-bottom: 5px;
    text-align: center;

    border-radius: 20px;
    font-weight: 500;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
