.configs {
  &__trial-check {
    &__base {
      width: 100%;
      height: 100%;
    }

    &__button {
      margin-top: 10px;
      text-align: right;

      button {
        font-size: 1.1em;
      }
    }
  }
}
