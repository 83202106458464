@import "resources/Vars.style.scss";

.map {
  &__tip {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    border-radius: 30px;
    padding: 12px 15px;
    font-weight: 500;

    background-color: #d9d9d9;
    color: #2e3b42;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

@media (max-width: 400px) {
  .map {
    &__tip {
      font-size: 0.8em;
    }
  }
}
