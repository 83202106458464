@import "resources/Vars.style.scss";

.configs {
  &__payment-method {
    &__base {
      width: 100%;
      height: 100%;
    }
    
    &__button {
      margin-top: 10px;
      text-align: right;

      button {
        font-size: 1.1em;
        padding: 10px 20px;
        border: 2px solid $light-gray;
      }
    }

    &__radio {
      margin-bottom: 20px;
      display: block;
      width: 100%;
      
      span {
        font-size: 1em !important;
        color: $white;
        font-weight: 400;
        margin-left: 10px;
      }
    }
  }
}
