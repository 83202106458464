@import 'resources/Vars.style.scss';
@import 'resources/Mixins.style.scss';

.routes-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 24px;
  padding-right: 0px;
  border-bottom: 1px solid #151515;
  cursor: pointer;
  position: relative;

  &:first-child {
    border-top: 1px solid #151515;
  }

  @media (max-width: 520px) {
    .routes-list-item__container-right__item,
    .routes-list-item__container-info__edit {
      visibility: visible;
    }
  }

  &:hover {
    background-color: #2e3b42;

    .routes-list-item__container-right,
    .routes-list-item__container-info__edit {
      visibility: visible;
    }
  }

  &__container-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;

    &__textbox {
      width: 100%;
      height: 24px !important;
      max-width: 220px;
      display: inline-block !important;
    }

    &__edit {
      font-size: 12px !important;
      width: 25px;
      height: 15px;
      color: $active-gray !important;
      visibility: hidden;
      transition: 0ms !important;

      &--end {
        font-size: 20px !important;
        width: 30px;
        color: $primary-color !important;

        &:hover {
          color: $primary-light !important;
        }
      }

      &:hover {
        color: $white !important;
      }
    }

    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 400;
      display: inline-block;
    }

    &__details {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      &__item {
        display: flex;
        flex-direction: column;

        &__title {
          color: #818181;
          font-size: 10px;

          margin-bottom: 4px;

          &__distance {
            margin-right: 32px;
          }

          &__flight-time {
            margin-right: 48px;
          }
        }

        &__distance-info {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
        }

        &__value {
          display: block;

          color: #cccccc;
          font-size: 15px;
          font-weight: 500;
        }

        &__unit {
          display: block;

          color: #818181;
          font-size: 10px;

          margin-left: 3px;
        }
      }

      // font-size: 0.9em;
      // color: $active-gray;
    }

    strong {
      color: gray;
    }
  }

  &__container-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 72px;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(270deg, #151515 0%, #151515 47.92%, rgba(21, 21, 21, 0) 100%);

    visibility: hidden;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__container-delete {
    text-align: center;
    width: 100%;

    &__text {
      margin-bottom: 10px;
      font-weight: bold;
    }

    &__button {
      display: inline-block;
      padding: 10px;
      font-weight: bold;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.yes {
        background-color: red;

        &:hover {
          background-color: rgba(255, 0, 0, 0.8);
        }
      }

      &.no {
        background-color: $light-gray;

        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}
