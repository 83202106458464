$primary-color: #00a877;
$primary-light: #00cc91;
$primary-bright: #a9fdda;
$primary-dark: #0f8f5a;

$disabled-color: #bfbfbf;
$panel-background: #13181b;

$white: #fcfcfc;
$black: #0d1012;
$windows-scrollbar: #bfbfbf;
$gray: #1a1a1a;
$sl-gray: #1e262a;
$light-gray: rgba(255, 255, 255, 0.2);
$active-gray: rgba(255, 255, 255, 0.6);
$item-gray: #3b3b3b;
$dropdown-gray: #3c3c3c;
$dropdown-border: #333333;

$warning-color: #ffff00;
$error-color: #e71123;

// Inversed
$warning-inversed: #ffedc6;
$error-inversed: #fa9e9e;
$label-inversed: #b3b3b3;
$gray-inversed: #dddddd;
$dark-gray-inversed: #9ea39b;

$page-background: #fafafa;
$wrapper-width: 1100px;
