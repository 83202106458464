@import 'resources/Vars.style.scss';

.route-planning-item {
  &__warning-message {
    background-color: #fdbe32;
    color: #0d1012;
    padding: 8px 16px;
    font-size: 0.8rem;
    font-weight: bold;
  }

  &__remove-button {
    position: absolute;
    top: 0px;
    right: 12px;
    font-size: 2.6rem;
    color: $active-gray;
    cursor: pointer;
    padding: 11px 10px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.not-first {
      top: 33px;
    }

    &:hover {
      color: #fdbe32;
      cursor: pointer;
    }

    &--disabled {
      color: #777777 !important;
      cursor: not-allowed !important;
    }
  }

  &__container {
    cursor: grab;
    position: relative;

    padding: 0px 24px;
    transition: all 300ms ease;
    min-height: 80px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    background-color: #1e262a;

    &.on-dragging {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
  }

  &__infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 90%;

    &__name {
      width: 100%;
      color: white;
      font-size: 0.9rem;
      font-weight: bold;
      margin-bottom: 3px;
    }

    &__type {
      color: #999;
      font-size: 0.9rem;
      font-weight: 400;

      &__copy {
        background: transparent;
        border: none;
        padding: 0;
        margin: 0 0 0 10px;
        color: #fcfcfc;
        cursor: pointer;
        font-size: 15px;
        visibility: hidden;
      }
    }

    &__type:hover button {
      visibility: visible;
    }

    &__textbox {
      width: 90% !important;
      display: inline-block !important;
    }

    &__edit {
      margin-left: 5px;
      cursor: pointer;

      &--end {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  &__actions {
    position: absolute;
    top: -2px;
    right: 55px;
    font-size: 2.3rem;
    color: #717577;
    cursor: pointer;
    padding: 13px 10px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    a {
      font-size: 1.6rem;
      color: $white;

      &:hover {
        color: #fdbe32;
        cursor: pointer;
      }
    }
  }
}
