@import "resources/Vars.style.scss";

///////*************  MEDIAS BIGGER THAN 920 PX OR SMALLER THAN 320 PX ***************/////////////

.main-drawer {
  position: fixed;
  top: 0;
  left: 0;

  width: 50px;
  min-width: 50px;
  height: 100%;
  background-color: $panel-background !important;
  opacity: 1;
  z-index: 10000;

  transition: all 0.4s;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  //TURNING DRAWER UNSELECTABLE ON ALL BROWSERS
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.open {
    width: 256px;
    min-width: 256px;
    .main-drawer__icon__tooltipMenu,
    .main-drawer__icon__tooltipMap,
    .main-drawer__icon__tooltipPlan,
    .main-drawer__icon__tooltipHistory,
    .main-drawer__icon__tooltipFavorites,
    .main-drawer__icon__tooltipMarket,
    .main-drawer__icon__tooltipBeta,
    .main-drawer__icon__tooltipFeedback,
    .main-drawer__icon__tooltipConfig,
    .main-drawer__icon__tooltip-account {
      display: none !important;
    }
  }

  &__icon {
    cursor: pointer;
    font-size: 1.3rem;
    padding: 14px;
    position: relative;

    &--user {
      padding: 5px;
    }

    &-label {
      overflow: hidden !important;
    }

    &:hover {
      .main-drawer__icon__tooltipMenu,
      .main-drawer__icon__tooltipMap,
      .main-drawer__icon__tooltipPlan,
      .main-drawer__icon__tooltipHistory,
      .main-drawer__icon__tooltipFavorites,
      .main-drawer__icon__tooltipMarket,
      .main-drawer__icon__tooltipBeta,
      .main-drawer__icon__tooltipFeedback,
      .main-drawer__icon__tooltipConfig {
        display: inline-block;
        animation-delay: 1000ms !important;
        transition-delay: 1s !important;
      }
    }

    &.active {
      background-color: $primary-color;
    }

    &__user {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-left: 3px;
    }

    &__nexAtlasIcoHorizontal {
      margin-top: 5px;
      margin-left: 3px;
    }

    &__map,
    &__plan,
    &__history,
    &__market,
    &__beta {
      margin-right: 15px;
      float: left;
    }

    &__faq {
      margin-top: 5px;
      margin-right: 15px;
      float: left;
    }

    &__news {
      margin-left: 1px;
      margin-right: 13px;
      float: left;
    }

    &__app {
      margin-left: 1px;
      margin-right: 13px;
      float: left;
    }

    &__config {
      margin-right: 15px;
      float: left;
    }

    &.has-tooltip {
      position: relative;
    }

    &__tooltipMenu,
    &__tooltipMap,
    &__tooltipPlan,
    &__tooltipHistory,
    &__tooltipFavorites,
    &__tooltipMarket,
    &__tooltipBeta,
    &__tooltipFeedback,
    &__tooltipConfig {
      display: none;
      max-width: 250px;
      white-space: nowrap;
      width: auto;
      background-color: $panel-background;
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      text-align: center;
      text-overflow: ellipsis;
      padding: 4px 8px;
      position: absolute;
      z-index: 1;
      bottom: -20px;
      right: 1px !important;
      transform: translateX(100%);
      transition: all 0.25s 0s ease-in-out;
      overflow: hidden;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }

    &__tooltipConfig {
      bottom: 15px !important;
    }
  }

  &__icon-label {
    transition: all 0.6s;
    margin-left: 16px;
    font-size: 1rem;
    white-space: nowrap;

    &.active {
      font-weight: bold;
    }
  }
}
