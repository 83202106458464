@import "resources/Vars.style.scss";

///////*************  MEDIAS BIGGER THAN 920 PX OR SMALLER THAN 320 PX ***************/////////////
.configs {
  position: fixed;
  top: 0;
  left: 48px;
  width: 350px;
  height: 100%;
  border-left: 3px solid #050505;

  @media screen and (max-width: 400px) {
    width: calc(100% - 45px);
  }

  display: flex;
  flex-direction: column;

  background-color: $panel-background;
  z-index: 9999;

  &__base {
    width: 100%;
    height: 100%;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 32px;
    padding: 24px 0px 0px 24px;
    flex: 0;

    &__action {
      font-size: 1.2em !important;
      margin-right: 15px;
      cursor: pointer;
    }
  }

  &__scroll {
    height: 100%;
  }

  &__container {
    padding: 0 25px 45px 25px;
    flex: 1;

    &__session {
      margin-bottom: 30px;
    }

    &__paragraph {
      margin-bottom: 20px;
      font-weight: 400;
    }

    &__link {
      color: $primary-light;
      font-weight: 400;
      margin-top: 4px;
      cursor: pointer;
    }

    hr {
      border-color: $active-gray;
      margin: 10px 0 30px 0;
    }

    table {
      width: 100%;
      margin-bottom: 15px;

      th {
        font-weight: 400;
      }
    }

    &__td1 {
      width: 75%;
      text-align: left;
    }

    &__td2 {
      width: 25%;
      text-align: right;
    }

    p {
      line-height: 1.5em;
    }

    em {
      font-weight: 400;
    }

    h2 {
      margin-bottom: 4px;
      font-size: 1em;
      font-weight: 400;
    }

    &__terms {
      &__title {
        margin-bottom: 15px;
        font-weight: 400;
      }

      &__p {
        margin-bottom: 10px;
      }
    }
  }

  &__bottom {
    flex: 0;
    text-align: right;
    padding: 20px 25px;
  }

  &__link {
    color: $primary-light;
    cursor: pointer;
    display: inline;
  }
}

///////*************  MEDIAS 320-520  PX ***************/////////////
@media (min-width: 320px) and (max-width: 520px) {
  .configs {
    position: fixed;
    top: 0;
    left: 48px;
    width: 100%;
    height: 100%;
    font-size: 12px;
    border-left: 3px solid #050505;

    @media screen and (max-width: 520px) {
      width: calc(100% - 45px);
    }

    display: flex;
    flex-direction: column;

    background-color: $panel-background;
    z-index: 9999;

    &__title {
      font-weight: bold;
      margin-bottom: 15px;
      padding: 20px 0px 0px 20px;
      flex: 0;
      font-size: 12px;
    }

    &__container {
      padding: 0 25px 45px 25px;

      &__session {
        margin-bottom: 30px;
      }

      em {
        color: $primary-color;
      }

      h2 {
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 1rem;
      }
    }
  }
}

///////*************  MEDIAS 521-720 PX ***************/////////////
@media (min-width: 521px) and (max-width: 720px) {
  .configs {
    position: fixed;
    top: 0;
    left: 48px;
    width: 50% !important;
    height: 100%;
    font-size: 14px;
    border-left: 3px solid #050505;

    @media screen and (max-width: 720px) {
      width: calc(100% - 45px);
    }

    display: flex;
    flex-direction: column;
    background-color: $panel-background;
    z-index: 9999;

    &__title {
      font-weight: bold;
      margin-bottom: 15px;
      padding: 20px 0px 0px 20px;
      flex: 0;
      font-size: 14px;
    }

    &__container {
      padding: 0 25px 45px 25px;

      &__session {
        margin-bottom: 30px;
      }

      em {
        color: $primary-color;
      }

      h2 {
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 1rem;
      }
    }
  }
}

///////*************  MEDIAS 721-920 PX ***************/////////////
@media (min-width: 721px) and (max-width: 920px) {
  .configs {
    position: fixed;
    top: 0;
    left: 48px;
    width: 350px;
    height: 100%;
    border-left: 3px solid #050505;

    @media screen and (max-width: 400px) {
      width: calc(100% - 45px);
    }

    display: flex;
    flex-direction: column;

    background-color: $panel-background;
    z-index: 9999;

    &__title {
      font-weight: bold;
      margin-bottom: 32px;
      padding: 24px 0px 0px 24px;
      flex: 0;
    }

    &__container {
      padding: 0 25px 45px 25px;

      &__session {
        margin-bottom: 30px;
      }

      em {
        color: $primary-color;
      }

      h2 {
        margin-bottom: 15px;
        font-size: 15px;
      }
    }
  }
}
