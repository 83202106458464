@import "resources/Vars.style.scss";

.splash {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
  max-width: $wrapper-width;
  margin: 0 auto;

  &__icon {
    height: 10%;
    margin-bottom: 30px;

    -webkit-animation: flickerAnimation 1.5s infinite;
    -moz-animation: flickerAnimation 1.5s infinite;
    -o-animation: flickerAnimation 1.5s infinite;
    animation: flickerAnimation 1.5s infinite;
    animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  &__text {
    font-weight: 500;
    color: $primary-color;
    text-align: center;
    line-height: 1.4em;
    font-size: 1.2em;
    visibility: hidden;

    &--active {
      visibility: visible;
    }
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
