@import 'resources/Vars.style.scss';

.beta-banner {
  
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000000;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &__content {
    margin: 0 20px;
    max-width: 759px;
    max-height: 531px;
    border-radius: 10px;
    background-color: #fcfcfc;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    
    &__header {
      display: flex;
      justify-content: flex-end;
      
      &__close {
        margin: 24px 24px 0 0;
        cursor: pointer;
      }
    }
    
    &__body {
      display: flex;
      flex-direction: row;
      gap: 26px;
      padding: 0 40px 40px 40px;
      overflow-y: auto;

      &__image-box {
        overflow: hidden;
        border-radius: 10px;
        min-height: 215px;
        min-width: 160px;

        background-image: url('../../assets/beta-indication.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      &__text {
        display: flex;
        flex-direction: column;
        gap: 26px;
        &__title__container {
      
          h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 115%;
            color: #000000;
          }
        }

        &__button__container {
          align-self: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 43px;
          background-color: rgb(19, 24, 27);
          box-shadow: rgba(30, 38, 42, 0.5) -2px 4px 6px 0px;
          border-radius: 20px;

          a {
            text-decoration: none;
            color: #FFFFFF;
            font-size: 16px;
            letter-spacing: 0px;
            line-height: 115%;
            font-weight: 700;
            padding: 16px 20px;
          }

          a:active {
            text-decoration: none;
            color: #FFFFFF;
          }
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 130%;
          color: #1E262A;

          strong {
            font-weight: bolder;
            color: black;
          }
        }
      }


    }
  }

  @media screen and (max-width: 840px) {
    &__content {
      max-width: 459px;
      max-height: 315px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

      &__body{
        gap: 14px;
        padding: 10px;
        max-height: 260px;

        &__image-box {
          min-height: 60px;
          min-width: 100px;
        }
  
        &__text {
          gap: 10px;
          &__title__container {
          
            h1 {
              font-size: 20px;
            }
          }
        
          p {
            font-size: 12px;
          }
        }
      }

      &__header {
        
        &__close {
          margin: 12px 12px 0 0;
        }
      }
    }

    
  }
}
