@import "resources/Vars.style.scss";

.tour {
  &__container {
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: space-between;

    :last-child {
      margin-bottom: 0 !important;
    }
  }

  &__center {
    align-self: center;
    justify-self: center;
  }

  &__feedback {
    &__comment {
      width: auto !important;
      margin-bottom: 10px;
    }
  }

  &__button {
    cursor: pointer;
    margin-top: 10px;

    height: 30px;
    width: 50%;
    background-color: $primary-dark;
    border: none;
    align-self: flex-end;
    outline: none;
    font-weight: 500;
    color: $white;
    text-transform: uppercase;

    &:disabled {
      cursor: not-allowed;
      background-color: $disabled-color;
      color: $active-gray;
    }

    &--important {
      width: 100%;
      align-self: stretch;
    }

    &--cancel {
      background-color: $disabled-color;
    }
  }
}