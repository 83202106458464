.auth {
  &__terms-modal {
    display: none;

    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    position: fixed;
    top: 0;
    left: 0;

    text-align: justify;
    background-color: #EEEEEE;
    z-index: 1000;

    color: #111111;
    padding: 60px 40px;

    &__content {
        max-width: 1000px;
        width: 100%;
        margin: 0 auto;
    }

    &__close {
      position: fixed;
      top: 20px;
      right: 30px;
      cursor: pointer;
      font-size: 2rem;
    }

    p {
        margin: 10px 0;
        font-size: 1rem;
        line-height: 1.4rem;
    }

    ul {
        font-size: 1rem;
        margin-left: 30px;
        li {
            margin: 10px 0;
        }
    }

    h2 {
      display: block;
      margin: 0 0 40px 0;
      text-align: center;
      font-size: 2.5rem !important;
      font-weight: 100 !important;
    }
    
    h3 {
        font-size: 1.1rem;
        margin: 25px 0 10px 0;
        font-weight: 600;
    }

    &--open {
      display: block;
    }
  }
}
