.configs {
  &__dependants {
    &__session {
      margin-top: 50px;
    }

    &__title {
      font-weight: 400;
      margin-bottom: 10px;
    }

    &__button {
      margin-top: 30px;

      display: flex;
      justify-content: flex-end;
    }
  }
}
