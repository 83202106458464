.app__content {
  padding: 20px 60px 0 24px;

  &__logo {
    display: flex;
    flex-direction: row;
  }

  &__app-info {
    margin-left: 8px;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    color: #fcfcfc;
    margin-right: 6px;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #bfbfbf;
  }

  &__description {
    margin-top: 24px;

    &__text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
    }

    &__subtext {
      margin-top: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #fcfcfc;
    }

    &-qrcode {
      margin-top: 24px;
      display: flex;
      flex-direction: column;

      &__text {
        display: flex;
        align-items: center;
        span {
          margin-left: 8px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 140%;
          color: #fcfcfc;
        }
      }

      &__image {
        margin-top: 24px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__subtext {
        margin-top: 24px;
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 130%;
          margin-bottom: 16px;
        }
      }
    }
  }

  &__buttons {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__play-store {
      margin-right: 11px
    }

    @media screen and (max-width: 400px) {
      flex-direction: column;
      align-items: center;
      

      &__play-store {
        margin-right: 0px
      }
    }

    a:hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }
}
