.auth {
  &__create {
    &__pilot-container {
      display: flex;
      justify-content: flex-start;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    &__pilot-radio {
      flex: 1;
      display: block;
      width: 100%;
      max-width: 100px;

      span {
        font-size: 1rem;
      }
    }

    &__dropdown {
        margin-bottom: 20px;
    }
  }
}
