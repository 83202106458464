@import 'resources/Vars.style.scss';

.auth {
  &__info-container {
    position: relative;
    
    background-color: $white;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #bfbfbf;

    width: 100%;
    max-width: 400px;
    min-height: 100px;

    padding: 20px 30px;

    &__title {
      font-size: 1.2rem;
      font-weight: bold;
      margin: 0 0 15px 0;
      color: $black;
    }

    &__close {
      position: absolute;
      right: 6px;
      top: -3px;
      width: 30px;
      
      font-size: 2rem;
      color: $black;
      text-align: center;

      cursor: pointer;
      outline: none;

      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

      &:hover {
        color: #1e262a;
      }
    }

    &__content-line {
      color: $error-color;
      margin-bottom: 10px;
    }
  }
}
