@import "resources/Vars.style.scss";

.configs {
  &__trial-end {
    &__button {
      margin-top: 10px;
      text-align: right;

      button {
        font-size: 1.1em;
        padding: 10px 20px;
        border: 2px solid $light-gray;
      }
    }
  }
}
