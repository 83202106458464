@import "resources/Vars.style.scss";

///////************* REACT-UWP FLOAT NAV SUBSCRIBED ***************/////////////
.float-nav-wrapper-2548725167,
.float-nav-wrapper-2548725167 div,
.float-nav-root-3223032382.map__nav {
  width: 40px !important;
  margin: 0 !important;
}

.Popover-body {
  display: inline-flex;
  flex-direction: column;
  padding: 2rem 4rem;
  background: hsl(0, 0%, 27%);
  color: white;
  border-radius: 0.3rem;
}

.Popover-tipShape {
  fill: hsl(0, 0%, 27%);
}

//////*************  MEDIAS BIGGER THAN 920 PX OR SMALLER THAN 320 PX ***************/////////////
.map {
  &__nav {
    position: fixed;
    right: 15px;
    top: 50%;
    opacity: 0.9 !important;
    transform: translateY(-50%);
    overflow-x: none !important;
    color: $panel-background !important;

    //TURNING ALL MAP-NAV-OPTIONS UNSELECTABLE OVER ALL BROWSERS
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__item {
      width: 40px !important;
      height: 50px !important;
      background-color: $panel-background !important;
      &--active {
        background-color: $primary-color !important;
      }

      &__tooltip-ruler,
      &__tooltip-adjust,
      &__tooltip-zoomIn,
      &__tooltip-zoomOut {
        display: flex;
        top: 35px !important;
        left: -70px !important;
        align-items: center !important;
        justify-content: center !important;
        background: $panel-background !important;
        width: 70px !important;
        height: 35px !important;
      }

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
    }
  }
}

@media (min-width: 320px) and (max-width: 520px) {
  .map {
    &__nav {
      position: fixed;
      right: 15px;
      top: 50%;
      z-index: 0;
      opacity: 0.9;
      transform: translateY(-50%);

      //TURNING ALL MAP-NAV-OPTIONS UNSELECTABLE OVER ALL BROWSERS
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &__item {
        width: 40px !important;
        height: 50px !important;
        background-color: $panel-background !important;

        &__ruler {
          margin-left: 8px !important;
          width: 22px !important;
        }

        &--active {
          background-color: $primary-color !important;
        }

        &__tooltip-ruler,
        &__tooltip-adjust,
        &__tooltip-zoomIn,
        &__tooltip-zoomOut {
          display: flex;
          top: 35px !important;
          left: -70px !important;
          align-items: center !important;
          justify-content: center !important;
          background: $panel-background !important;
          width: 70px !important;
          height: 30px !important;
        }

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
      }
    }
  }
}
