@import 'resources/Vars.style.scss';

.route-panel__header__actions__dropdown {
  position: relative;

  display: inline-block;

  background-color: $dropdown-gray;

  z-index: 5;

  &__button {
    width: 210px;
    height: 45px;
    padding: 0 10px;
    border: none;
    border-bottom: 1px solid $dropdown-border;

    background: none;
    outline: none;

    color: $white;
    font-size: 16px;
    text-align: left;

    cursor: pointer;
  }

  &__content {
    display: none;
    position: absolute;
    top: 0;
    left: 210px;
  }

  &:hover > &__content {
    display: inline-block;
  }

  &:hover > &__button {
    background-color: $dropdown-border;
  }
}

.route-panel__header__actions__dropdown--first {
  top: -14px;
  & > .route-panel__header__actions__dropdown__content {
    left: 0;

    display: inline-block;
  }
}
