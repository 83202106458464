@import "resources/Vars.style.scss";

.map {
    &__route {
        &__marker {
            &__tooltip {
                background-color: $black !important;
                border: 1px solid $black !important;

                color: $white !important;
                font-weight: 500;
                padding: 2px 5px !important;

                &::before {
                    border-top-color: $black !important;
                }
            }
        }
    }
}