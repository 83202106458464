@import "resources/Vars.style.scss";

.map {
  &__input-search {
    position: fixed;
    top: 15px;
    left: 75px;
    z-index: 9000 !important;

    &__coord-search {
      padding: 20px;
      color: $black;
      font-weight: 400;
    }

    &__input input,
    &__input span {
      color: #555555;
    }

    &__input:focus {
      box-shadow: "inset 0px 0px 0 2px #bfbfbf !important";
    }

    &__input input:focus {
      color: #000000;
    }

    &__box {
      background-color: $white;
      width: 296px;
      height: auto;
      max-height: 400px;
      overflow-x: hidden;
      overflow-y: auto;

      border-left: solid 1px #bfbfbf;
      border-right: solid 1px #bfbfbf;
      border-bottom: solid 1px rgb(191, 191, 191);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &::-webkit-scrollbar {
        width: 10px !important;
      }

      &::-webkit-scrollbar-track {
        border: solid 3px transparent !important;
        background: #ffffff !important;
      }

      &::-webkit-scrollbar-thumb {
        // rbg(191, 191, 191) is equal to #bfbfbf
        box-shadow: inset 0 0 6px 6px rgb(191, 191, 191) !important;
        border: solid 3px #ffffff !important;
        border-radius: 14px !important;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 400px) {
  .map {
    &__input-search {
      left: 60px;
    }
  }
}
