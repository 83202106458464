@import 'resources/Vars.style.scss';

.leaflet-tooltip {
  z-index: 1000 !important;
  pointer-events: auto !important;
}

.map {
  &__route {
    &__marker {
      &__tooltip {
        background-color: $black !important;
        border: 1px solid $black !important;

        color: $white !important;
        font-weight: 500;

        transition: width 3s;

        &::before {
          border-top-color: $black !important;
        }

        &:hover {
          .map__route__marker__tooltip__action {
            display: inline-block;
          }

          &.info {
            margin-left: -10px;
          }
        }

        &--highlighted {
          font-size: 1.2em;
        }

        &__action {
          color: $white !important;
          margin-left: 5px;
          font-size: 14px;
          display: none;
        }
      }
    }
  }
}
