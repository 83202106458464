@import "resources/Vars.style.scss";

.confirm-modal {
  &__overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10010;
  }

  &__window {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    font-weight: 400;
    text-align: center;

    padding: 1.5em 1em;

    border: 1px solid $active-gray;
    background-color: $panel-background;
  }

  &__actions {
      display: flex;
      justify-content: space-between;

      margin-top: 10px;

      &__button {
          width: 50%;
        flex-grow: 1;
        margin: 5px;
      }
  }
}
