@import 'resources/Vars.style.scss';

.auth {
  &__button {
    padding: 6px 20px;

    background-color: transparent;
    border: 1px solid $primary-light;
    color: $primary-light;
    font-size: 1.1rem;
    outline: none;
    cursor: pointer;

    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, border 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &:hover,
    &:focus {
      border: 1px solid $primary-bright;
      color: $primary-bright;
    }

    &--filled {
      background-color: $sl-gray;
    }

    &--disabled,
    &--disabled:focus,
    &--disabled:hover {
      border: 1px solid #888888;
      color: #888888;
      cursor: not-allowed;
    }
  }
}
