@import "resources/Vars.style.scss";

.configs {
  &__trial-finalize {
    &__button {
      margin-top: 10px;
      text-align: right;

      button {
        font-size: 1.1em;
        padding: 10px 20px;
        border: 2px solid $light-gray;
      }
    }
    
    &__alert {
      background-color: $warning-color;
      color: $black;

      text-align: center;
      font-weight: 500;
      padding: 10px 20px;
      margin-bottom: 20px;
    }

    &__title {
      font-weight: 500;
      margin-bottom: 2px;
    }

    &__text {
      font-weight: 400;
      line-height: 1.7em;
      margin-bottom: 1px;
    }

    &__link {
      color: $primary-light;
      font-weight: 500;
      margin-bottom: 1px;
      cursor: pointer;

      &:hover {
        color: $primary-color;
      }
    }
  }
}
