@import "resources/Vars.style.scss";

.vs-input {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-right: 8px;
    font-weight: 400;

    &.disabled {
      color: $disabled-color;
    }
  }
}