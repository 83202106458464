@import "resources/Vars.style.scss";

.app {
  position: fixed;
  top: 0;
  left: 48px;
  width: 350px;
  height: 100%;
  border-left: 3px solid #050505;

  @media screen and (max-width: 400px) {
    width: calc(100% - 45px);
  }

  display: flex;
  flex-direction: column;

  background-color: $panel-background;
  z-index: 9999;

  &__header {
    display: flex;
    flex-direction: row;
    align-content: space-between;

    align-items: center;
    border-bottom: 1px solid #151515;
  }

  &__title {
    font-weight: bold;
    flex: 1;
    padding: 20px 25px;
    color: white;
  }

  &__scroll {
    height: 100%;
  }
}

@media (max-width: 520px) {
  .app {
    font-size: 12px;
    border-left: 3px solid #050505;

    @media screen and (max-width: 520px) {
      width: calc(100% - 48px);
    }
  }
}

///////*************  MEDIAS 521-720 PX ***************/////////////
@media (min-width: 521px) and (max-width: 720px) {
  .app {
    font-size: 14px;
    border-left: 3px solid #050505;
  }
}

///////*************  MEDIAS 721-920 PX ***************/////////////
@media (min-width: 721px) and (max-width: 920px) {
  .app {
    font-size: 14px;
    border-left: 3px solid #050505;
  }
}