.configs {
  &__user-waypoint-manager {
    &__title {
      margin-bottom: 10px;
      height: 40px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      cursor: pointer;
    }
    &__base {
      width: 100%;
      height: 100%;

      &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 25px;
        
        &__inputs {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;

          input {
            width: 100%;
          }
        }

        &__example {
          width: 100%;
          margin-top: -10px;
          margin-bottom: 10px;
          color: #777;
          
          & > span {
            text-align: left;
          }
        }

        &__buttons {
          display: flex;
          justify-content: space-around;
          margin-top: 10px;

          &__button {
            width: 137px;
          }
        }
      }
    }
  }
}
