@import 'resources/Vars.style.scss';

.auth {
  &__background {
    background-image: url('../../../../pages/Auth/assets/background.png');
    background-size: cover;
    background-color: #344748;
    width: 100%;
    height: 100%;

    @media (max-width: 600px) {
      background-image: none;
      background-color: #262626;
    }

    @media (min-width: 1920px) {
      background-image: url('../../../../pages/Auth/assets/background@2x.png');
    }
  }

  &__container-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 500px;

    @media (max-width: 600px) {
      position: static;
      transform: none;
      max-height: 100%;
      overflow-y: auto;
    }
  }

  &__info-wrapper {
    position: absolute;
    bottom: -1000px;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;
    max-width: 400px;

    animation: slideBottom 0.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &__bottom {
    position: fixed;
    bottom: 0;

    width: 100%;
    background-color: $black;
    padding: 5px 20px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &__terms {
      cursor: pointer;
      outline: none;

      &:hover {
        color: $gray-inversed;
      }
    }
  }
}

@keyframes slideBottom {
  100% {
    bottom: -10px;
  }
}
