@import 'resources/Vars.style.scss';

.beta-info {
  position: fixed;
  top: 0;
  left: 48px;
  width: 380px;
  height: 100%;
  border-left: 3px solid #050505;
  background-color: $panel-background;
  z-index: 9999;

  @media screen and (max-width: 430px) {
    width: calc(100% - 48px);
  }

  overflow-y: hidden;

  &__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 37px 0 20px;

    &__title {
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      padding-top: 20px;
      width: 100%;
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 14px;

      &__subtitle {
        margin-top: 24px;
        font-size: 14px;
        letter-spacing: 0.1px;
        line-height: 130%;
        font-weight: 600;
      }

      &__content {
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 130%;
        font-weight: 300;

        &__bullet-list {
          margin: 4px 0;
          font-size: 14px;
          letter-spacing: 0.3px;
          line-height: 130%;
          font-weight: 300;
          padding-left: 34px;
          list-style: initial;
        }
      }

      &__button {
        align-self: center;
        border: none;
        cursor: pointer;
        text-decoration: none;
        height: 43px;
        width: fit-content;
        padding: 14px 19px;
        border-radius: 25px;
        background-color: #1E262A;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        font-size: 14px;
        letter-spacing: 0px;
        line-height: 115%;
        font-weight: 700;
        transition: background-color 0.2s ease;

        &:hover {
        background-color: #2e3b42d5;
        }
      }
    }
  }
}



