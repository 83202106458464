@import 'resources/Vars.style.scss';

.auth {
    &__base {
        width: 100%;
        height: 100%;
    }

    &__em {
        color: $primary-light;
    }
}

.grecaptcha-badge {
    bottom: 30px !important;
}