@import "resources/Vars.style.scss";

.configs {
  &__main {
    &__base {
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }

    &__title {
      font-weight: 500;
      margin-bottom: 5px;
    }

    &__warning {
      color: $warning-color;
      font-weight: 500;
    }

    &__text {
      font-weight: 400;
      margin-bottom: 5px;
    }

    &__link {
      color: $primary-light;
      font-weight: 500;
      margin-bottom: 5px;
      cursor: pointer;

      &:hover {
        color: $primary-color;
      }
    }
  }
}
