@import "../../resources/Vars.style.scss";

.map-main {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;

  &__map-wrapper {
    width: 100%;
    height: 100%;
    margin-left: 50px;
  }

  &__new-button {
    position: absolute;
    top: 10px;
    right: 9px;
    z-index: 10;

    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    height: 43px;
    background-color: rgb(19, 24, 27);
    box-shadow: rgba(30, 38, 42, 0.5) -2px 4px 6px 0px;
    border-radius: 20px;

    text-decoration: none;
    color: #FFFFFF;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 115%;
    font-weight: 700;
  }
}

#map-tour {
  position: absolute;
  width: calc(100% - 50px - 60px);
  height: calc(100%);
  margin-left: 50px;
  bottom: 0;
  z-index: 1;
}
