@import "resources/Vars.style.scss";

.news-list-item {
  padding: 20px 25px;
  border-bottom: 1px solid #151515;
  position: relative;

  &:first-child {
    border-top: 1px solid #151515;
  }

  &__date {
    font-weight: 400;
    margin-bottom: 5px;
  }

  &__title {
    margin-bottom: 20px;
    font-weight: 400;
    color: $primary-light;
  }

  &__message {
    font-size: 1em;
    line-height: 1.5em;
  }

  &__image {
    border: 1px solid $white;
    margin-top: 15px;
  }
}
